import { Suspense, useEffect, useState } from 'react';

import type { XingletLoaderProps } from '@xing-com/crate-xinglet';
import { useSuspenseXinglet } from '@xing-com/crate-xinglet';
import type { Runtime } from '@xing-com/crate-xinglet/internal';

import { XingletErrorBoundary } from './xinglet-error-boundary';

export type InternalXingletLoaderProps = XingletLoaderProps & {
  runtime: Runtime;
};

const DevWrapper: React.FC<
  React.PropsWithChildren<{ name: string; runtime: Runtime }>
> = ({ children, runtime, ...rest }) => {
  const [{ name }, setState] = useState<{ name: string }>({ name: rest.name });

  useEffect(() => {
    return runtime.events.on('xinglet-reloaded', (otherName) => {
      if (otherName === name) setState({ name });
    });
  }, [runtime, name]);

  return children;
};

export const InternalXingletLoader: React.FC<InternalXingletLoaderProps> = ({
  fallback,
  name,
  runtime,
  ...rest
}) => {
  const Xinglet = useSuspenseXinglet(name);

  if (LOCAL_DEVELOPMENT) {
    return (
      <DevWrapper name={name} runtime={runtime}>
        <Xinglet {...rest} />
      </DevWrapper>
    );
  }

  return <Xinglet {...rest} />;
};

export const XingletLoader: React.FC<InternalXingletLoaderProps> = ({
  error: errorFallback = null,
  fallback = null,
  name,
  runtime,
  ...rest
}) => {
  if (runtime.isServer && !runtime.metadataMap[name]?.ssr) return fallback;

  return (
    <XingletErrorBoundary
      fallback={errorFallback}
      runtime={runtime}
      xinglet={name}
    >
      <Suspense fallback={fallback}>
        <InternalXingletLoader {...rest} name={name} runtime={runtime} />
      </Suspense>
    </XingletErrorBoundary>
  );
};
